import './utils/sentrySetup';
import { LicenseInfo } from '@mui/x-license';
import { createRoot } from 'react-dom/client';

import '@styles/global.module.css';

import { App } from '@components/App';
import { getEnvironment } from '@hooks/useEnvironment';

LicenseInfo.setLicenseKey(getEnvironment().muiProLicenceKey);

const rootElement = document.getElementById('root') as Element;
const root = createRoot(rootElement);

root.render(<App />);
